import styled from '@emotion/styled'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.625rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 11.25rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  max-width: 82.125rem;
  margin: 0 auto;
`

const Title = styled.h1`
  ${styles.h1}
`

const Description = styled.div`
  ${styles.description}
  margin-top: 5rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
    &::first-letter {
      -webkit-initial-letter: unset;
      initial-letter: unset;
      padding: 0;
      margin-top: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style: inside;
  }
`
